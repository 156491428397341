import React, { Component } from 'react';
import {
    Table, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    FormGroup, Input, Row, Col, Label, Badge
} from 'reactstrap';
export class PlexContent extends Component {
    static displayName = PlexContent.name;

    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            isMovies: true,
            filteredTitles: []
        }
        this.titles = [];
        this.getFilteredRef = React.createRef(AbortController | null);
    }

    componentDidMount() {
        this.getTitles();
    }

    onTypeChanged = async e => {
        e.preventDefault();
        await this.setState({
            isMovies: !this.state.isMovies
        });
        this.getTitles();
    }

    updateFilter = async (e) => {
        await this.setState({ filter: e.target.value });
        this.getFilteredTitles();
    }

    getTitles = async () => {
        const response = await fetch(`api/plex/titles/${this.state.isMovies ? 'movies' : 'tv'}`);
        const data = await response.json();

        this.titles = data.titles.map(t => {
            return {
                key: t.key,
                name: t.title ?? t.name,
                summary: t.summary,
                year: t.year,
                rated: t.contentRating,
                rating: this.state.isMovies ? t.rating : 'N/A',
                audienceRating: t.audienceRating,
                dateAdded: new Date(t.dateAdded).toLocaleDateString(),
                runtime: this.state.isMovies ? t.runtime : 'N/A',
                genre: t.genre,
                resolution: t.media ? t.media.resolution : null,
                thumb: t.thumb,
                seasons: t.seasons?.length
            };
        });

        this.getFilteredTitles();
    }

    getFilteredTitles = async () => {
        //Check to see if we need to abort the previous call
        if (this.getFilteredRef.current) {
            this.getFilteredRef.current.abort();
        }
        //Create a new AbortController and assign it to the controllerRef
        const controller = new AbortController();
        this.getFilteredRef.current = controller;

        let filteredTitles = this.state.filter && this.state.filter.length >= 2 ?  this.titles.filter(t => t.name.indexOf(this.state.filter) > -1) : this.titles;
        await this.setState({
            filteredTitles: filteredTitles
        }, () => this.getFilteredRef.current = null);
    }

    render = () => (
        <div>
            <div className = "d-flex flex-row align-items-center mb-2" >
                <div className="h4 text-plex mb-0">
                    Plex Content
                    {/*<Badge pill color="plex" className="ms-2">*/}
                    {/*    {this.state.filteredTitles.length} Titles*/}
                    {/*</Badge>*/}
                </div>
            </div>
            <Row className="my-2 d-flex flex-row align-items-center">
                <Col xs="auto">
                    <div className="ms-2">
                        <label className="toggle toggle-plex" data-on="Movies" data-off="TV">
                            <input type="checkbox" name="isMovies" checked={this.state.isMovies} onChange={this.onTypeChanged} />
                            <span></span>
                        </label>
                    </div>
                </Col>
                <Col>
                    <FormGroup floating>
                        <Input type="text" value={this.state.filter} onChange={this.updateFilter} />
                        <Label>Search Filter</Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-md-none">
                        <ListGroup flush>
                            {this.state.filteredTitles.map(t => {
                                let rtImage = t.rating > 6.0
                                    ? 'tomatometer-fresh.svg'
                                    : t.rating === 0
                                        ? 'tomatometer-empty.svg'
                                        : 'tomatometer-rotten.svg';
                                let audImage = t.audienceRating > 6.0
                                    ? 'aud_score-fresh.svg'
                                    : t.audienceRating === 0
                                        ? 'aud_score-empty.svg'
                                        : 'aud_score-rotten.svg';
                                return <ListGroupItem key={t.key} className="px-0">
                                    <ListGroupItemHeading className="text-break">
                                        <span className="text-plex">{t.name}</span> <span className="">({t.year})</span>
                                        
                                    </ListGroupItemHeading>
                                    
                                    <ListGroupItemText tag="div" >
                                        <div className="d-flex flex-row">
                                            <div className="flex-fill">
                                                <div className="d-flex flex-row flex-wrap gap-1 align-items-center">
                                                    <span>{t.genre}</span>
                                                    •
                                                    {this.state.isMovies ?
                                                        <>
                                                            <span>{t.runtime}</span>
                                                            •
                                                        </>
                                                        : <>
                                                            <span>{t.seasons} Seasons</span>
                                                            •
                                                        </>}

                                                    <span>{t.rated}</span>
                                                </div>
                                                <div className="d-flex flex-row flex-wrap gap-1 align-items-center">

                                                    {this.state.isMovies ?
                                                        <React.Fragment>
                                                            <img src={`images/${rtImage}`} alt="" className="me-2" width="16" height="16" />
                                                            {t.rating * 10}%
                                                        </React.Fragment>
                                                        : null
                                                    }
                                                    <img src={`images/${audImage}`} alt="" width="16" height="16" />
                                                    {t.audienceRating * 10}%
                                                </div>
                                            </div>
                                            <Badge pill className="ms-auto align-self-center" color={
                                                t.resolution === 'UHD'
                                                    ? 'plex'
                                                    : t.resolution === 'FHD'
                                                        ? 'primary'
                                                        : t.resolution === 'HD'
                                                            ? 'danger'
                                                            : 'secondary'}>{t.resolution} {t.resolution === 'UHD'
                                                                ? '2160P'
                                                                : t.resolution === 'FHD'
                                                                    ? '1080P'
                                                                    : t.resolution === 'HD'
                                                                        ? '720P'
                                                                        : 'STD' }</Badge>
                                        </div>
                                        
                                    </ListGroupItemText>
                                </ListGroupItem>
                            })}
                        </ListGroup>
                    </div>
                    <Table striped className="d-none d-md-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Genre</th>
                                <th className="text-center">Year</th>
                                <th className="text-center">Rated</th>
                                {this.state.isMovies ?
                                    <>
                                        <th className="text-center">Resolution</th>
                                        <th className="text-center">Rating</th>
                                    </>
                                    : <th className="text-center">Seasons</th>
                                }
                                
                                <th className="text-center">Audience Rating</th>
                                <th className={this.state.isMovies ? "text-center" : "text-end"}>Date Added</th>
                                {this.state.isMovies ?
                                    <>
                                        <th className="text-end">Runtime</th>
                                    </> : null}
                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filteredTitles.map(t => {
                                let rtImage = t.rating > 6.0
                                    ? 'tomatometer-fresh.svg'
                                    : t.rating === 0
                                        ? 'tomatometer-empty.svg'
                                        : 'tomatometer-rotten.svg';
                                let audImage = t.audienceRating > 6.0
                                    ? 'aud_score-fresh.svg'
                                    : t.audienceRating === 0
                                        ? 'aud_score-empty.svg'
                                        : 'aud_score-rotten.svg';
                                return (
                                    <tr key={t.key}>
                                        <td className="text-break text-plex">{t.name}</td>
                                        <td>{t.genre}</td>
                                        <td className="text-center">{t.year}</td>
                                        <td className="text-center">{t.rated}</td>
                                        {this.state.isMovies ?
                                            <>
                                                <td className="text-center">{t.resolution === 'UHD'
                                                    ? '2160P'
                                                    : t.resolution === 'FHD'
                                                        ? '1080P'
                                                        : t.resolution === 'HD'
                                                            ? '720P'
                                                            : ''}</td>
                                                <td className="text-center">
                                                    <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                                                        <img src={`images/${rtImage}`} alt="" width="16" height="16" />
                                                        {t.rating * 10}%
                                                    </div>
                                                </td>
                                            </> : 
                                            <td className="text-center">{t.seasons}</td>
                                        }
                                        
                                        <td className="text-center">
                                            <div className="d-flex flex-row align-items-center justify-content-center gap-2">
                                                <img src={`images/${audImage}`} alt="" className="ms-2" width="16" height="16" />
                                                {t.audienceRating * 10}%
                                            </div>
                                        </td>
                                        <td className={this.state.isMovies ? "text-center" : "text-end"}>{t.dateAdded}</td>
                                        {this.state.isMovies ?
                                            <>
                                                <td className="text-end">{t.runtime}</td>
                                            </> : null}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </div>
    )
}