import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, Button,
    NavItem, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

    toggleNavbar() {
        if (this.state.collapsed) {

        }
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-black border-none box-shadow mb-3" dark>
                    <NavbarBrand tag={Link} to="/"><img src="/images/nerdyobjects-logo.png" alt="NerdyObjects" /></NavbarBrand>
                    <svg className={!this.state.collapsed ? 'active ham hamRotate navbar-toggler border-0' : 'ham hamRotate navbar-toggler border-0'} viewBox="0 0 60 60" width="60" onClick={this.toggleNavbar}>
                        <path className="line top"
                            d="m 10,13 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                        <path className="line middle"
                            d="m 10,30 h 40" />
                        <path className="line bottom"
                            d="m 50,47 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                    </svg>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <LoginMenu onNavLinkClicked={this.toggleNavbar} />
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
