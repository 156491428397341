import React, { Component } from 'react';
import {
    Row, Col, InputGroup, InputGroupText, Button, Input,
    Offcanvas, OffcanvasHeader, OffcanvasBody,
    Spinner, FormGroup, Label, Badge,
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from 'reactstrap';
import * as Icon from 'react-bootstrap-icons';
import { SearchResult } from './SearchResult';
import { TrendingList } from './TrendingList';
import { toast } from 'react-toastify';
import authService from './api-authorization/AuthorizeService';
import FlyoutMenu from './FlyoutMenu';
import { ViewSettings } from './ViewSettings';

export class Home extends Component {
    static displayName = Home.name;

    constructor() {
        super();
        const searchParams = new URLSearchParams(window.location.search);
        var title = searchParams.get('title');
        if (!title) {
            title = '';
        }
        this.state = {
            tmdbItem: null,
            searchTerm: '',
            year: '',
            resolution: '',
            type: 'multi',
            strictSearch: true,
            includeRar: false,
            season: '',
            episode: '',
            group: '',
            trendingMovies: [],
            trendingTV: [],
            torrents: [],
            noTorrentsFound: false,
            topTorrentsHidden: false,
            isNavHidden: true,
            navOptions: [],
            options: [],
            showDropdown: false,
            filtersShown: false,
            viewSettingsShown: false,
            wishlistItems: [],
            subscribedShows: [],
            userViewSettings: {
                showTrendingTv: false,
                showTrendingMovies: false,
                showUpcomingMovies: false,
                showUpcomingTv: false
            }
        };
        this.onInputChanged.bind(this);
        this.onCheckChanged.bind(this);
        this.onSearchClick.bind(this);
        this.clearSearch.bind(this);
        this.trendingClicked.bind(this);
        this.toggleTopTorrents.bind(this);
        this.showHideBar.bind(this);
        this.removeWishlistItem.bind(this);
        this.toggleFilters.bind(this);
        this.toggleViewSettings.bind(this);

        this.getUserViewSettings();

        if (this.state.searchTerm?.length > 0) {
            this.searchTitle();
        }

        this.downloadsRef = React.createRef(null);
        this.getMoviesRef = React.createRef(AbortController | null);
        this.searchTitlesRef = React.createRef(AbortController | null);
    }

    showHideBar = e => {
        var isHidden = window.scrollY < 100 || this.state.navOptions.length < 2;
        this.setState({ isNavHidden: isHidden });
        return true;
    }

    componentDidMount = async () => {
        //window.addEventListener('scroll', this.showHideBar);
        this.user = await authService.getUser();
        this.getSubscriptions();
        this.getWishlistItems();
    }

    componentWillUnmount = () => {
        //window.removeEventListener('scroll', this.showHideBar);
    }

    onCheckChanged = e => {
        let updated = {};
        updated[e.target.name] = e.target.checked;
        this.setState(updated);
    }

    onInputChanged = e => {
        let updated = {};
        updated[e.target.name] = e.target.value;
        this.setState(updated);
    }
    searchKeyDown = e => {
        if (e.key === 'Enter') {
            e.currentTarget.nextSibling.focus();   
            this.onSearchClick();
        }
    }
    
    onSearchClick = e => {
        if (this.state.searchTerm && this.state.searchTerm !== '') {
            this.searchTitle();
        }
    }

    clearSearch = e => {
        this.setState({
            tmdbItem: null,
            searchTerm: '',
            year: '',
            resolution: '',
            type: 'multi',
            strictSearch: true,
            includeRar: false,
            season: '',
            episode: '',
            group: '',
            noTorrentsFound: false,
            torrents:[],
            searchResults: [],
            wishlistItems: [],
            subscriptions: []
        });
        this.getSubscriptions();
        this.getWishlistItems();
    };

    trendingClicked = async (type, data) => {
        data.mediaType = type;
        await this.setState({
            tmdbItem: data,
            searchTerm: data.displayName,
            year: data.releaseYear,
            options: [],
            searchResults: [],
            torrents: [],
            loadingTorrents: true
        });
        this.searchTitle();
        this.getMovies(data.displayName);
    }

    onAutoCompleteInputChanged = e => {
        let value = e.target.value;
        let updated = {};
        updated[e.target.name] = value;
        this.setState(updated);

        //if we have 2 or more characters, show the dropdown
        if (value.length > 2) {
            this.getMovies(value);
        }
    }

    itemSelected = async (option) => {
        await this.setState({
            tmdbItem: option,
            searchTerm: option.displayName,
            year: option.releaseYear
        });
        this.searchTitle();
    }

    toggleTopTorrents = () => {
        this.setState({ topTorrentsHidden: !this.state.topTorrentsHidden });
    }   

    addToWishlist = async (e, item) => {
        if (!item) {
            return;
        }

        let user = await authService.getUser();

        const response = await fetch(`/api/torrents/wishlist/add?id=${item.id}&type=${item.mediaType}&userId=${user.sub}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: { }
        });
        const result = await response.json();
        if (result.success === true) {
            toast.success(result.message);
        } else {
            toast.error(result.message);
        }
        e.preventDefault();
    }

    toggleViewSettings = async e => {
        if (e) {
            e.preventDefault();
        }
        await this.setState({ viewSettingsShown: !this.state.viewSettingsShown });
    }

    viewSettingsUpated = async s => {
        await this.setState({
            userViewSettings: s
        });
        this.toggleViewSettings(null);
    }

    toggleFilters = async (e) => {
        if (e) {
            e.preventDefault();
        }
        var newState = !this.state.filtersShown;
        await this.setState({ filtersShown: newState });
    }

    applyFilters = async (e) => {
        await this.toggleFilters(e);
        this.searchTitle();
    }

    clearFilters = async (e) => {
        e.preventDefault();
        await this.setState({
            year: '',
            resolution: '',
            type: 'multi',
            strictSearch: true,
            includeRar: false,
            season: '',
            episode: '',
            group: ''
        });
        this.applyFilters();
    }

    removeWishlistItem = async (e, id) => {
        e.preventDefault();
        const response = await fetch(`/api/torrents/wishlist/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: {}
        });
        const data = await response.json();
        if (data.success) {
            toast.success("Item successfully removed from your wishlist.");
            this.getWishlistItem();
        }
    }

    onRadioChanged = (e) => {
        let updated = {};
        updated[e.target.name] = e.target.value;
        this.setState(updated);
    }

    subscriptionChanged = () => {
        this.getSubscriptions();
    }

    getWishlistItem = (id) => {
        return this.state.wishlistItems?.find(c => c.id === id);
    }

    getUserViewSettings = async () => {
        const user = await authService.getUser();
        const response = await fetch(`/api/user/settings?userId=${user.sub}`);
        const result = await response.json();
        if (result.success) {
            await this.setState({
                userViewSettings: {
                    showTrendingTv: result.data.showTrendingTv,
                    showTrendingMovies: result.data.showTrendingMovies,
                    showUpcomingMovies: result.data.showUpcomingMovies,
                    showUpcomingTv: result.data.showUpcomingTv
                }
            });
        } else {
            toast.error("Error retrieving user view settings");
            await this.setState({
                userViewSettings: {
                    showTrendingTv: true,
                    showTrendingMovies: true,
                    showUpcomingMovies: true,
                    showUpcomingTv: true
                }
            });
        }
    }

    getSubscriptions = async () => {
        const response = await fetch('/api/torrents/tv/subscriptions?sort=');
        const data = await response.json();
        let subs = data.map(tv => {
            return {
                id: tv.tmdbSeriesId,
                title: tv.title,
                season: tv.season,
                userId: tv.userId
            };
        });
        this.setState({ subscriptions: subs });
    }

    getWishlistItems = async () => {
        const response = await fetch('/api/torrents/wishlist?sort=');
        const data = await response.json();
        let wlItems = data.map(wl => {
            return {
                ...wl,
                wishlistId: wl.id,
                id: wl.tmdbId
            };
        });
        this.setState({ wishlistItems: wlItems });
    }

    getMovies = async (search) => {
        //Check to see if we need to abort the previous call
        if (this.getMoviesRef.current) {
            this.getMoviesRef.current.abort();
        }
        //Create a new AbortController and assign it to the controllerRef
        const controller = new AbortController();
        this.getMoviesRef.current = controller;

        this.setState({ loading: true });
        try {
            const response = await fetch(`/api/tmdb/search/${search}?type=multi&page=1`, {
                signal: this.getMoviesRef.current?.signal
            });
            const data = await response.json();
            this.setState({ options: data.results, loading: false });
            this.getMoviesRef.current = null;
        } catch (e) {

        }
    }

    searchTitle = async () => {
        if (this.searchTitlesRef.current) {
            this.searchTitlesRef.current.abort();
        }

        if (!this.state.searchTerm || this.state.searchTerm.trim() === '') {
            return;
        }

        const controller = new AbortController();
        this.searchTitlesRef.current = controller;

        try {
            //this.setState({ loadingTorrents: true, noTorrentsFound: false });
            let params = {
                type: this.state.type,
                includerar: this.state.includeRar,
                includeclosematches: !this.state.strictSearch
            };
            if (this.state.year) {
                params['year'] = this.state.year;
            }
            if (this.state.resolution) {
                params['resolution'] = this.state.resolution;
            }
            if (this.state.season !== '' || this.state.episode !== '') {
                params['season'] = `${this.state.season !== '' ? `s${this.state.season.toString().padStart(2, '0')}` : ''}${this.state.episode !== '' ? `e${this.state.episode.toString().padStart(2, '0')}` : ''}`;
            }
            if (this.state.group) {
                params['group'] = this.state.group;
            }
            var query = '?';
            for (var prop in params) {
                query += `${prop}=${params[prop]}&`;
            }
            query = query.substring(0, query.length - 1);
            const response = await fetch(`/api/torrents/list/${this.state.searchTerm}${query}`, params, {
                signal: this.searchTitlesRef.current?.signal
            });
            const data = await response.json();
            let total = 0;
            if (data) {
                data.forEach(g => {
                    g.tmdbId = this.state.tmdbItem?.id;
                    total += g.total;
                });
            }
            await this.setState({
                torrents: data,
                loadingTorrents: false,
                totalResults: total,
                noTorrentsFound: total === 0,
                navOptions: data.map(g => g.section)
            });
            //document.getElementById('downloads').scrollIntoView({ behavior: 'smooth' });
            this.searchTitlesRef.current = null;
        } catch (e) { }
    }

    render() {
        let groups = [
            { name: 'all', display: 'All Values', value: '' },
            { name: 'preferred', display: 'Preferred Only', value: 'preferred' },
            { name: 'LAMA', value: 'lama' },
            { name: 'd3g', value: 'd3g' },
            { name: 'CMRG', value: 'cmrg' },
            { name: 'meGusta', value: 'megusta' }
        ];
        let wishlistItem = this.getWishlistItem(this.state.tmdbItem?.id)
        return (
            <div className="content">
                
                {this.state.isNavHidden ? '' :
                    <div className="d-block d-md-none bookmarks">
                        <FlyoutMenu>
                            {this.state.navOptions.map(o => <a key={`section-${o}`} href={'#' + o}>{o === 'number' ? '#' : o}</a>)}
                        </FlyoutMenu>
                    </div>
                }
                <Row className="row g-2 align-items-center">
                    <Col xs={12} className="g-2 me-0 position-relative">
                        <InputGroup>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                            <Input autoFocus type="text" className="rounded-left" name="searchTerm" placeholder="Search for title..." value={this.state.searchTerm}
                                onKeyDown={this.searchKeyDown} onChange={this.onAutoCompleteInputChanged}
                                onBlur={async (event) => { await this.setState({ showDropdown: false }); }}
                                onFocus={async (event) => { await this.setState({ showDropdown: true }); }}/>
                            <Button color="tertiary" tooltip="Clear" onClick={this.clearSearch} className="border m-0 "><Icon.XLg /></Button>
                            <Button color="plex" onClick={this.onSearchClick} className="m-0">Search</Button>
                        </InputGroup>   
                        {this.state.searchTerm && this.state.searchTerm.length > 2 && this.state.showDropdown
                            ?
                            <div className="autocomplete-dropdown scroll scroll-plex">
                                {this.state.options.length <= 0
                                    ?
                                    <Row className="d-flex flex-col h-100">
                                        <Col className="text-center my-auto align-self-middle">
                                            <Spinner type="grow"> </Spinner>
                                        </Col>
                                    </Row>
                                    :
                                    <ListGroup className="rounded-0 border-0">
                                        {this.state.options.map((o, i) => (
                                            <ListGroupItem key={`sr-${o.id}`} action tag="button" onMouseDown={(event) => { this.itemSelected(o); }} className="d-flex flex-row align-items-start">
                                                <img src={o.posterXS} alt={o.displayName} className="me-2 autocomplete-image" />
                                                <div className="flex-fill d-flex flex-column">
                                                    <ListGroupItemHeading>{o.displayName} ({o.releaseYear})</ListGroupItemHeading>
                                                    <ListGroupItemText>{o.overview}</ListGroupItemText>
                                                </div>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                }
                            </div>                                
                            :
                            null
                        }
                        
                    </Col>
                    <Col xs={12} className="me-0">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <Button color="link" className="text-decoration-none text-plex p-0 d-flex align-items-center" onClick={this.toggleFilters}><Icon.Filter />&nbsp;Filters</Button>
                            <Offcanvas fade={true} isOpen={this.state.filtersShown} toggle={this.toggleFilters}>
                                <OffcanvasHeader toggle={this.toggleFilters} className="border-bottom">
                                    <div className="d-flex align-items-center"><Icon.Filter className="me-2" />Search Filters</div>
                                </OffcanvasHeader>
                                <OffcanvasBody>
                                    <Row className="g-2 me-1 align-items-center">
                                        <Col xs={12}>
                                            <div className="fw-semibold mb-2">Media Type</div>
                                            <FormGroup check>
                                                <Input name="type" type="radio" checked={this.state.type === 'multi'} onChange={this.onRadioChanged} value="multi" id="multi-check" />{' '}
                                                <Label check for="multi-check" className="d-block">All</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input name="type" type="radio" checked={this.state.type === 'movie'} onChange={this.onRadioChanged} value="movie" id="movie-check" />{' '}
                                                <Label check for="movie-check" className="d-block">Movies</Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Input name="type" type="radio" checked={this.state.type === 'tv'} onChange={this.onRadioChanged} value="tv" id="tv-check" />{' '}
                                                <Label check for="tv-check" className="d-block">TV</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="fw-semibold mb-2">Year</div>
                                            <Input type="number" name="year" placeholder="Year..." inputMode="numeric" min={1901} max={2026} value={this.state.year} onKeyDown={this.searchKeyDown}
                                                onChange={this.onInputChanged} />
                                        </Col>
                                        <Col xs={12}>
                                            <Col xs={12}>
                                                <div className="fw-semibold mb-2">Resolution</div>
                                                <FormGroup check>
                                                    <Input name="resolution" type="radio" checked={this.state.resolution === ''} onChange={this.onRadioChanged} value="" id="all-check" />{' '}
                                                    <Label check for="all-check" className="d-block">All Resolutions</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input name="resolution" type="radio" checked={this.state.resolution === '2160p'} onChange={this.onRadioChanged} value="2160p" id="uhd-check" />{' '}
                                                    <Label check for="uhd-check" className="d-block">UHD (2160p)</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input name="resolution" type="radio" checked={this.state.resolution === '1080p'} onChange={this.onRadioChanged} value="1080p" id="fhd-check" />{' '}
                                                    <Label check for="fhd-check" className="d-block">FHD (1080p)</Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Input name="resolution" type="radio" checked={this.state.resolution === '720p'} onChange={this.onRadioChanged} value="720p" id="hd-check" />{' '}
                                                    <Label check for="hd-check" className="d-block">HD (720p)</Label>
                                                </FormGroup>
                                            </Col>
                                        </Col>
                                        <Col xs={12}>
                                            <div className="fw-semibold mb-2">Season Number</div>
                                            <Input type="number" name="season" placeholder="Season..." inputMode="numeric" min={1} max={50} value={this.state.season} onKeyDown={this.searchKeyDown}
                                                onChange={this.onInputChanged} />
                                        </Col>
                                        <Col xs={12}>
                                            <div className="fw-semibold mb-2">Episode Number</div>
                                            <Input type="number" name="episode" placeholder="Episode..." inputMode="numeric" min={1} max={50} value={this.state.episode} onKeyDown={this.searchKeyDown}
                                                onChange={this.onInputChanged} />
                                        </Col>
                                        <Col xs={12}>
                                            <div className="fw-semibold mb-2">Group</div>
                                            {groups.map(group => {
                                                return (
                                                    <FormGroup key={`${group.name}-check`} check>
                                                        <Input name="group" type="radio" checked={this.state.group === group.value} onChange={this.onRadioChanged} value={group.value} id={`${group.name}-check`} />{' '}
                                                        <Label check for={`${group.name}-check`} className="d-block">{group.display ? group.display : group.name}</Label>
                                                    </FormGroup>
                                                )
                                            })
                                            }
                                        </Col>
                                        <Col xs={12}>
                                            <div className="fw-semibold mb-2">General</div>
                                            <FormGroup check>
                                                <Input type="checkbox" name="includeRar" checked={this.state.includeRar} onChange={this.onCheckChanged} />{'  '}
                                                <Label check className="mb-0">Include RAR</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Input type="checkbox" name="strictSearch" checked={this.state.strictSearch} onChange={this.onCheckChanged} />{'  '}
                                                <Label check className="mb-0">Strict Search</Label>
                                                <span className="text-muted ms-2"><small>If you are having trouble getting matches or finding titles, disable this to allow for less strict search filtering.</small></span>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <Button color="plex" onClick={this.applyFilters}>Apply</Button>
                                            <Button color="plex" outline onClick={this.clearFilters} className="ms-2">Clear Filters</Button>
                                        </Col>
                                    </Row>
                                </OffcanvasBody>
                            </Offcanvas>

                            <Button color="link" className="p-0 text-plex text-decoration-none d-flex align-items-center" onClick={this.toggleViewSettings}><Icon.Sliders />&nbsp;View Settings</Button>
                            <Offcanvas fade={true} direction="end" isOpen={this.state.viewSettingsShown} toggle={this.toggleViewSettings}>
                                <OffcanvasHeader toggle={this.toggleViewSettings} className="border-bottom">
                                    <div className="d-flex align-items-center"><Icon.Sliders className="me-2" />View Settings</div>
                                </OffcanvasHeader>
                                <OffcanvasBody>
                                    <ViewSettings onSettingsUpdated={this.viewSettingsUpated} />
                                </OffcanvasBody>
                            </Offcanvas>
                        </div>
                    </Col>

                </Row>
                <Row className="mt-3">
                    {this.state.userViewSettings.showTrendingMovies
                        ?
                        <Col xs={12} className="mb-3">
                            <TrendingList minimal card title="Trending Movies" type="movie" onItemClicked={this.trendingClicked} />
                        </Col>
                        : null
                    }
                    {this.state.userViewSettings.showUpcomingMovies
                        ?
                        <Col xs={12} className="mb-3">
                            <TrendingList minimal card title="Upcoming Movies" type="movie" upcoming onItemClicked={this.trendingClicked} />
                        </Col>
                        : null
                    }
                    {this.state.userViewSettings.showTrendingTv
                        ?
                        <Col xs={12} className="mb-3">
                            <TrendingList minimal card title="Trending TV" type="tv" onItemClicked={this.trendingClicked} />
                        </Col>
                        : null
                    }
                    {this.state.userViewSettings.showUpcomingTv
                        ?
                        <Col xs={12} className="mb-3">
                            <TrendingList minimal card title="Upcoming TV" type="tv" upcoming onItemClicked={this.trendingClicked} />
                        </Col>
                        : null
                    }
                </Row>
                <Row className="mt-3 gx-4">
                    <Col xs={12} className="">
                        <div id="#results"></div>
                        <div id="downloads" className="d-flex flex-column">
                            <h4 className="d-flex flex-row gap-2">Downloads <Badge color="secondary">{this.state.torrents ? this.state.torrents.length : 0}</Badge></h4>

                            {this.state.loadingTorrents
                                ?
                                <Row className="pt-5 mt-5 minh-800">
                                    <Col className="text-center">
                                        <Spinner type="grow"> </Spinner>
                                    </Col>
                                </Row>
                                : null
                            }
                            {this.state.torrents.map((g, index) =>

                                <section id={g.section} key={`torrent-group-${g.section}`} className="mb-2">
                                    <Row className="g-2">
                                        {g.torrents.map(t =>
                                            <Col key={'row-' + t.uniqueId} >
                                                <SearchResult data={t} tmdbId={g.tmdbId} lastMarker="#" subscriptions={this.state.subscriptions}
                                                    subscriptionChanged={this.subscriptionChanged} />
                                            </Col>
                                        )}
                                    </Row>
                                </section>
                            )}
                            {this.state.noTorrentsFound
                                ?
                                <div>
                                    <p className="lead mb-1">No downloads currently available for <span className="fw-semibold"><em><a className="link-plex" target="_blank" rel="noreferrer" href={`https://www.themoviedb.org/${this.state.tmdbItem?.mediaType ? this.state.tmdbItem.mediaType : 'movie'}/${this.state.tmdbItem?.id}` }>{this.state.tmdbItem.displayName}</a></em></span>.</p>
                                        {this.state.group !== ''
                                            ? <p className="mb-1">Your current search is only looking for items from {this.state.group}. Try selecting All Groups to see if any results are found.</p>
                                        : ''}

                                    {this.state.tmdbItem?.mediaType === 'movie' 
                                        ?
                                        !wishlistItem
                                            ?
                                            <div>
                                                <Button onClick={(event) => this.addToWishlist(event, this.state.tmdbItem)} color="link" className="text-plex text-decoration-none ps-0">Add {this.state.searchTerm} to your wishlist</Button>
                                                <p><small>We'll check periodically to see if any wishlist items become avialable and download them automatically.</small></p>
                                            </div>
                                            :
                                            <div className="lead">
                                                <p>This item was added to the wishlist on {new Date(wishlistItem.dateAdded).toLocaleDateString()}.<br />
                                                We will download it automatically once it becomes available.</p>
                                                {
                                                    this.user.sub === wishlistItem.user.id
                                                        ? <Button color="link" className="text-plex text-decoration-none ps-0 pt-0" onClick={e => this.removeWishlistItem(e, wishlistItem.wishlistId) }>Remove item from wishlist</Button>
                                                        : null
                                                }
                                            </div>
                                        : null
                                    }
                                        
                                </div>
                                :
                                null}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
